@import "../../definitions";

.highlight-item {
  @include constrain-width($max-site-width, "max");
  @include margin($content-header-padding, "bottom");
  color: $color-text;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 6fr 6fr;
  list-style-type: none;
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    @include margin(36, "bottom");
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 8fr 4fr;
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
    @include margin(0, "bottom");
    grid-auto-flow: row;
    grid-template-columns: auto;
  }
}

.highlight-item:last-child {
  @include margin(0, "bottom");
}

.highlight-item__body {
  // Add min-width to fix issue with authors. Without that width the ellipsis are not working
  min-width: 0;

  .author-line {
    display: none;

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      @include font-size(14);
      display: block;
      font-family: $font-secondary;
      text-overflow: unset;
      white-space: unset;
    }
  }

  .paragraph {
    display: none;
    font-family: $font-primary;
    margin-bottom: 0;

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      @include padding(12, "bottom");
      display: block;
    }
  }
}

.highlight-item__image {
  width: 100%;
}

.highlight-item__meta {
  color: $color-text-secondary;

  .meta {
    @include font-size-and-vertical-height(14, 24);
    color: inherit;

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      display: flex;
    }
  }

  .meta__type {
    &:hover {
      color: inherit;
    }
  }

  .date {
    @include font-size-and-vertical-height(14, 24);
  }
}

.highlight-item__picture-wrapper {
  @include margin(24, "left");
  display: inline-block;
  grid-column: 2;
  width: auto;

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
    @include margin(0, "left");
    @include padding(24, "bottom");
    grid-column: auto;
  }

  &:hover + .highlight-item__body .highlight-item__title_link {
    color: $color-primary-dark;
  }

  .highlight-item__picture {
    width: 100%;
  }
}

.highlight-item__title {
  @include font-size-and-vertical-height(16, 22);
  @include padding(12, "bottom");
  @include padding(0, "top");

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    @include font-size-and-vertical-height(18, 26);
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    @include font-size-and-vertical-height(22, 30);
  }

}

.highlight-item__title_link {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: $color-primary-dark;
  }
}
