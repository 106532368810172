@import "../../definitions";

.select {
  @include margin(6, "bottom");

  &:focus {
    border-color: $color-text-placeholder;
  }
}

.select--invalid {
  border-color: $color-attention;
}
