@import "../../definitions";

$showTwoAtWidth: #{get-rem-from-px($bkpt-site--wide)}rem;
$showThreeAtWidth: #{get-rem-from-px($bkpt-site--x-wide)}rem;
$totalHighlightsSpacing: 6;

.js {
  .grid__item.display-narrow {
    @media only all and (max-width: #{get-rem-from-px($bkpt-site--x-wide - 1)}em) {
      display: none;
    }
  }
}

/* .listing-list is a list of items. It is the major content within a listing column. A listing column may contain one
 *    or more listing lists. Every listing-list in each listing column has a bottom margin expect the last one.
 */
.listing-list {
  @include nospace("left");
  list-style-type: none;
  padding-bottom: 0;
}

.decision-letter-header {

  .listing-list {
    margin-bottom: 0;
  }

}

.listing-list__item {
  @include font-size-and-vertical-height($font-size-base-in-px, $font-size-base-in-px);
}

.listing-list--read-more {

  .content-header-divider {
    border: none;
  }

  .content-header--read-more {
    border: none;
  }

  // When wide, hide to avoid duplication: then, the contents is also displayed in the right hand article column
  .listing-list__item--related {

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
      display: none;

      // Breaks max 3 level selector rule. This is an extreme edge case, so grudingly accepting
      + .listing-list__item .listing-list__divider {
        display: none;
      }

    }

  }

}

.listing-list--read-more {
  text-align: center;
}

div.listing-list__content {
  @include margin($listing-list-spacing, "top");
  @include margin($listing-list-spacing, "bottom");
  @include padding($content-header-side-padding--extra-wide, "left");
  @include padding($content-header-side-padding--extra-wide, "right");
  display: none;
  @media only all and (min-width: #{get-rem-from-px($bkpt-grid-listing--narrow-screen)}em) {
    display: block;
  }

  > * {
    margin-left: 0;
    margin-right: 0;
  }

  .listing-list--read-more & {
    @include blg-spacing("top", "small", "margin");
    margin-bottom: 0;
  }

}

// Only on items that come after other items (to show line between but not before or after)
.listing-list__item:not(:first-child) .listing-list__divider {
  box-sizing: content-box;
  @include constrain-width(140, "max");
  margin: 0 auto;
  @include padding($blg-space-medium-in-px - 1, "top");
  padding-right: 0;
  @include blg-spacing("bottom", "medium");
  padding-left: 0;

  &:after {
    border-bottom: 1px solid $color-text-dividers;
    content: "";
    display: block;
    width: 100%;
  }
}

.listing-list-heading {
  letter-spacing: 0.5px;
  text-align: center;
}

// Sets up the column layout at medium width for the secondary content lists.
@media only all and (min-width: 550px) and (max-width: 799px) {

  .listing-column-secondary .listing-list {
    column-count: 2;
    column-gap: #{get-rem-from-px(26)}rem;
  }

  .listing-column-secondary .listing-list__item {
    break-inside: avoid;
  }

  .listing-column-secondary .see-more-link {
    column-span: all;
  }

}

.listing-list--highlights {
  @include margin(unquote("0 -#{$totalHighlightsSpacing / 2}"));

  .listing-list__item {
    border: 0 solid #ccc;
    border-bottom-width: 1px;

    // Adding extra pixel to account for removal of the top border sustains correct vertical rhythm.
    padding-bottom: 1px;

    // Breaks rule of only adding white space underneath. Tightly scoped to Highlights: shouldn't leak, but be careful.
    @include blg-spacing("top", "extra-small");

    @include margin(3, "left");
    @include margin(3, "right");

    .teaser--highlights {
      border-top: none;

      .js & {
        margin-bottom: 0;
      }

      padding: 0;
      white-space: normal;
    }
  }

}

.js {

  .listing-list--highlights {
    transition: transform 0.2s;
    white-space: nowrap;

    .listing-list__item {
      border-width: 1px;
      display: inline-block;
      list-style-type: none;
      @include margin(3, "left");
      @include margin(3, "right");
      @include padding(12, "left");
      @include padding(12, "right");
      padding-bottom: 0;

      vertical-align: top;

      /* Width of each element is 100% divided by the amount of elements shown in
       * a single view, minus (40px default) for the margins.
       */
      width: calc(100% - #{$totalHighlightsSpacing}px); // 1 in view (1/1)

      @media only all and (min-width: $showTwoAtWidth) {
        width: calc(50% - #{$totalHighlightsSpacing}px); // 2 in view (1/2)
      }

      @media only all and (min-width: $showThreeAtWidth) {
        width: calc(33.33333% - #{$totalHighlightsSpacing}px); // 3 in view (1/3)
      }
    }

    .teaser__header_text {
      @include truncate-with-ellipsis();
      padding-top: 0;
    }

    .teaser__secondary_info {
      @include truncate-with-ellipsis();
    }

    .teaser__picture {
      box-sizing: content-box;
      @include nospace();
      @include padding(3, "top");
      @include padding(12, "left");
    }

  }
}
