.modal-container {
  background-color: rgba(255,255,255,0.9);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.modal-content {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.22);
  display: grid;
  font-family: $font-secondary;
  margin: 0;
  padding: 36px;

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    margin: 24px;
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium + 48)}em) {
    margin: 180px auto 24px;
    max-width: 730px;
  }

  h6 {
    @include font-size-and-vertical-height($font-size-h6-in-px);
    @include padding(6, "bottom");
    grid-column: 1/3;
    grid-row: 1;
    justify-self: start;
    padding-top: 0;
  }

  .social-media-sharers {
    @include margin(0, "bottom");
    display: flex;
    grid-column: 1/3;
    grid-row: 4;
    justify-content: center;

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      grid-row: unset;
      justify-content: left;

      li {
        @include margin(0 4);

        &:first-child {
          @include margin(0, "left");

          a {
            @include margin(0, "left");
          }
        }
      }
    }
  }

  .reference {
    font-family: $font-secondary;
    grid-column: 1/3;

    .doi {
      @include margin(12, "top");
      @include margin(36, "bottom");
      display: flex;
    }
  }

  .button-collection {
    display: unset;
    flex-direction: column;
    grid-column: 1/3;

    .button-collection__item {
      float: none;
    }

    .button {
      @include margin(12, "bottom");
      width: 100%;
    }

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      grid-column: 1/3;

      .button-collection__item {
        float: left;
      }

      .button {
        @include margin(12, "bottom");
        @include padding(15 24 14 24);
        line-height: 1.5;
        text-decoration: none;
        width: auto;
      }
    }
  }
}

.modal-content__body {
  @include margin(30, "top");
  display: grid;
  grid-column: 1/3;
  grid-template-columns: 1fr 224px;

  .button {
    @include margin(36, "bottom");
    justify-self: center;
    grid-column: 1/3;
    grid-row: 3;

    &.button--default {
      @include padding(15 18);

      @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
        justify-self: right;
        grid-column: 2/3;
        grid-row: unset;
      }
    }
  }

  .form-item {
    @include margin(36, "bottom");
    grid-column: 1/3;

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      grid-column: 1/2;
    }
  }
}

.modal-content__show {
  opacity: 1;
  overflow-y: scroll;
  visibility: visible;
  z-index: 1000;
}

.modal-content__close-button {
  @include padding(0 25 0 0);
  background: url(../img/icons/close.f00467a1.svg) right 4px no-repeat;
  color: $color-text;
  font-family: $font-secondary;
  font-weight: bold;
  grid-column: 2/3;
  grid-row: 1;
  justify-self: end;
}

.modal-nojs {
  visibility: hidden;
}

.modal-content__clipboard-btn {
  min-width: 189px;
}
