@import "../../definitions";

.content-aside {
  @include padding(12, "top");

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    @include padding(24, "top");
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
    @include padding(86, "top");
    align-items: flex-end;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    z-index: 5;

    &.content-aside__sticky {
      @include padding(72, "top");
      max-height: 100vh;
      overflow-y: auto;
      position: sticky;
      top: 0;
    }

    .article__related-item {
      .listing-list {
        @include margin(72, "bottom");
      }
    }
  }

  .button-collection {
    &.button-collection--inline {
      @include margin(24, "bottom");

      .button-collection__item {
        @include margin(12, "bottom");
      }
    }
  }

  .contextual-data {
    border: none;
  }

  .contextual-data__list {
    border: none;
    display: inline-block;
    text-align: left;

    .contextual-data__item {
      @include padding(0);

      &:before {
        content: "";
      }

      &:after {
        content: "\00a0\2022\00a0";
        display: inline-block;
        width: 13px;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }

      &.no-separator {
        &:after {
          content: "";
        }
      }
    }
  }

  .content-aside__second-column {
    @include padding(24, "bottom");

    &:empty {
      display: none;
    }

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      width: 100%;
    }

    .definition-list--timeline {
      @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
        min-width: 220px;
      }

      @media only all and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
        min-width: auto;
      }
    }

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include padding(0, "bottom");
    }

    .divider {
      display: none;

      @media only all and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
        display: block;
      }
    }
  }

  .content-aside__column-wrapper {
    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      display: flex;
      grid-column: 2/12;
    }

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include content-aside-layout();
      @include padding(10, "left");
    }

    .content-aside__first-column {
      @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
        @include padding(48, "right");
        width: 50%;

        & + .content-aside__second-column {
          width: 50%;
        }
      }

      @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
        @include padding(0, "right");
        width: 100%;

        & + .content-aside__second-column {
          width: 100%;
        }
      }
    }
  }

  .content-aside__definition-list {
    @include margin(36, "bottom");

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include margin(48, "bottom");
      @include padding(6, "left");
    }
  }

  .js & .content-aside__definition-list {
    display: grid;

    &:not(.toggle--expanded) {
      .definition-list--active {
        @include margin(0, "top");
      }
    }

    .toggle {
      @include font-size-and-vertical-height(14, 24);
      @include margin(18, "top");
      color: $color-text;
      font-family: $font-secondary;

      &:before {
        @include arrow("down", 5);
        @include height(5);
        @include margin(3, "bottom");
        @include margin(10, "right");
        @include width(5);
        display: inline-block;
        transform-origin: 50% 50%;
      }

      &:after {
        @include padding(8, "left");
        content: "Show all versions";
      }

      &:hover {
        @include link-hover();
      }

      @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
        @include padding(0 17 0 0);
        @include margin(24, "top");
        grid-row: 2;
        justify-self: start;
      }
    }

    &.toggle--expanded {
      .toggle {
        &:before {
          @include arrow("up", 5);
        }

        &:after {
          content: "Hide all versions";
        }

        @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
          @include margin(0, "bottom");
        }
      }
    }
  }

  .definition-list {
    @include margin(0);
    grid-row: 1;
    overflow: hidden;
  }

  .previous-version-warning-container {
    @media only all and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include content-aside-layout();
    }
  }

  .status-wrapper {
    @include margin(24, "bottom");

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      grid-column: 2/12;
    }

    @media only all and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include content-aside-layout();
    }

    .status-title {
      @include padding(0, "top");
      color: $color-primary;
      line-height: 22px;

      @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
        @include padding(12, "top");
      }
    }

    .status-title__short {
      @include font-size(18);
    }

    .status-title__long {
      @include font-size(16);
    }

    .status-description {
      @include font-size-and-vertical-height(14, 20);
      @include margin(12, "bottom");
      font-family: $font-secondary;
    }

    .status-link {
      @include font-size-and-vertical-height(14, 22);
      color: $color-primary;
      font-family: $font-secondary;
      font-weight: 700;
    }
  }
}

.wrapper--content-with-header-and-aside {
  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);

    .content-header {
      &.content-header__has-aside {
        grid-column: 1/9;
        grid-row: 2;
      }

      .content-container-grid {
        display: block;
      }
    }

    .content-aside {
      grid-column: 9/13;
      grid-row: 2/4;
    }
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
    .content-header {
      &.content-header__has-aside {
        @include padding(36, "right");
        grid-column: 1/10;
      }
    }

    .content-aside {
      grid-column: 10/13;
    }
  }

  & > .wrapper {
    @include padding(0);
  }

  .content-container-block {
    grid-column: 1/10;
    grid-row: 2;
  }

  .wrapper--content {
    grid-column: 1/13;
    grid-row: 3;

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
      .view-selector,
      .main-content-grid {
        grid-column: 2/12;
        grid-row: 3;
      }

      .content-container-block {
        grid-column: 2/12;
      }
    }

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--extended-wide)}em) {
      @include padding(24, "top");

      .view-selector,
      .main-content-grid {
        grid-column: 1/9;
      }

      .content-container-block {
        grid-column: 1/9;
      }
    }

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
      @include padding(36, "top");

      .view-selector {
        grid-column: 1/3;
      }

      .main-content-grid {
        @include margin(36, "left");
        @include margin(36, "right");
        grid-column: 3/10;
        grid-row: 3;
      }

      .content-container-block {
        grid-column: 1/10;
        margin-right: 36px;
      }
    }
  }
}
