@import "../../definitions";

.orcid__icon {
  @include inline-image();
}

.orcid {

  &:hover {
    @include color-link-hover();
  }
}
