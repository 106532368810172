@import "../../definitions";

@mixin header_button {
  border: 1px solid $color-text-dividers;
  border-radius: #{$border-radius}px;
  display: inline-block;
  @include height(28);
  @include margin(-2, "top");
  margin-left: -1px;
  @include margin(-2, "bottom");
  @include width(28);
}

.asset-viewer-inline {
  clear: both;
  @include blg-spacing("top", "medium", "margin");
  @include blg-spacing("bottom", "medium", "margin");
}

.asset-viewer-inline__header_panel {
  @include blg-spacing("bottom", "extra-small");
}

.asset-viewer-inline__header_text {
  align-self: center;
  display: inline-block;
  @include fig-caption-text-typeg(0);
  @include nospace("bottom");
}

.asset-viewer-inline__header_text__prominent {
  @include fig-heading-typeg();
}

.asset-viewer-inline--table {

  .asset-viewer-inline__header_panel {
    border-bottom: 1px solid $color-text-dividers;
    @include padding($blg-space-extra-small-in-px - 1, "bottom");
    @include blg-spacing("bottom", "extra-small", "margin");
  }

}

.asset-viewer-inline__header_link {
  color: $color-text;
  font-family: $font-secondary;
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }
}

.asset-viewer-inline__header_navigation {
  float: left;
  @include margin(10, "right");
}

.asset-viewer-inline__previous {
  @include header_button();
  background: url("../img/icons/chevron-left.a32a2d6c.png") 50% 50% no-repeat;
  background: url("../img/icons/chevron-left.b03ee34f.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  margin-left: -1px;
  opacity: 0.25;
}

.asset-viewer-inline__previous--active {
  opacity: 1;
  &:hover {
    cursor: pointer;
    background: url("../img/icons/chevron-left-hover.934d7715.png") 50% 50% no-repeat;
    background: url("../img/icons/chevron-left-hover.c7f310bd.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  }
}

.asset-viewer-inline__next {
  @include header_button();
  background: url("../img/icons/chevron-right.2478d4cd.png") 50% 50% no-repeat;
  background: url("../img/icons/chevron-right.219ad16a.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  @include margin(3, "left");
  opacity: 0.25;
}

.asset-viewer-inline__next--active {
  opacity: 1;
  &:hover {
    cursor: pointer;
    background: url("../img/icons/chevron-right-hover.57561288.png") 50% 50% no-repeat;
    background: url("../img/icons/chevron-right-hover.f9f76141.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  }
}

.asset-viewer-inline__figure_access {
  // TODO: add spacing of $blg-space-extra-small-in-px between icons
  float: right;
  @include margin(10, "left");
  text-align: right;
}

.asset-viewer-inline__download_all_link {
  @include header_button();
  border: 1px solid $color-text-dividers;
  background: url("../img/icons/download.f825b06d.png") 50% 50% no-repeat;
  background: url("../img/icons/download.ecfa2d98.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  &:hover {
    background: url("../img/icons/download-hover.4b4ff33b.png") 50% 50% no-repeat;
    background: url("../img/icons/download-hover.ae139aa2.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
    border: 1px solid $color-text-dividers;
  }
}

.asset-viewer-inline__open_link {
  @include header_button();
  border: 1px solid $color-text-dividers;
  background: url("../img/icons/launch.9cff238f.png") 50% 50% no-repeat;
  background: url("../img/icons/launch.20baf437.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
  margin-right: -1px;
  &:hover {
    background: url("../img/icons/launch-hover.e3537830.png") 50% 50% no-repeat;
    background: url("../img/icons/launch-hover.44190b44.svg") 50% 50% no-repeat, linear-gradient(transparent, transparent);
    border: 1px solid $color-text-dividers;
  }
}
