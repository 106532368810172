//------------------------------------
//    $VARIABLES
//------------------------------------

// Colors
$color-primary: #087acc;
$color-primary-dark: #0769b0;
$color-primary-light: #b3e5fc;

$color-link-primary: #0b70b9;
$color-link-secondary: #6d6d6d;

$color-text: #212121;
$color-text-secondary: #757575;
$color-text-secondary-dark: #6e6e6e;
$color-text-placeholder: #bdbdbd;
$color-text-dividers: #e0e0e0;
$color-text-ui-background: #fff;
$color-text-ui-background-light-gray: #edeff4;
$color-text-ui-background-secondary: #f7f7f7;
$color-text-ui-background-secondary-grey: #d7d9de;
$color-text-ui-background-secondary-light: #f5f7fa;
$color-text-ui-background-secondary-light-2: #f8f9fb;
$color-text-ui-background-hue: #f5f5f5;
$color-text-ui-code: #f7f7f7;

$color-text--reverse: #fff;
$color-text-secondary--reverse: #9e9e9e;
// there is no $color-text-placeholder--reverse
$color-text-dividers--reverse: #616161;
$color-text-ui-background--reverse: #212121;
$color-text-ui-background-hue--reverse: #333;

$color-information: #087acc;
$color-success: #629f43;
$color-success-dark: #569037;
$color-attention: #cf0c4e;
$color-warning: #d14600;

$color-overlay: rgba(0, 0, 0, 0.8);

// Article Status and Timeline Styling

$color-status-variable-a: #666;
$color-status-variable-b: #2994d2;
$color-status-variable-c: #edeff4;
$color-status-variable-d: #42aea4;
$color-status-variable-e: #f29524;

// Typography
$font-primary: "Noto Serif", serif;
$font-secondary: "Noto Sans", Arial, Helvetica, sans-serif;
$font-monospace: "Courier 10 Pitch", Courier, monospace;
$font-size-base-in-px: 16;
$font-size-h1-in-px: 36;
$font-size-h2-in-px: 26;
$font-size-h3-in-px: 22;
$font-size-h4-in-px: 20;
$font-size-h5-in-px: 18;
$font-size-h6-in-px: 16;
$font-size-caption-in-px: 13;
$box-font-scaling-factor: 0.85;

/*
 * Baseline grid
 */
$blg-space-extra-extra-small-in-px: 6;
$blg-space-extra-small-in-px: 12;
$blg-space-small-in-px: $blg-space-extra-small-in-px * 2;
$blg-space-smallish-in-px: $blg-space-small-in-px * 1.5;
$blg-space-medium-in-px: $blg-space-small-in-px * 2;
$blg-space-large-in-px: $blg-space-small-in-px * 3;
$blg-space-extra-large-in-px: $blg-space-small-in-px * 5;

// Widths
$max-width-grid-listing-item: 400;
$max-width-block-link: 600;
$max-width-image-link: $max-width-block-link;
$med-width-grid-listing-item: 263;
$min-width-grid-listing-item: 245;
$grid-listing-spacing-measure: $blg-space-smallish-in-px;

$max-site-width: 1114;
$max-site-width-in-rem: get-rem-from-px($max-site-width);
$viewport-width-when-site-hits-max-width-in-px: 1245;
$viewport-width-when-site-hits-max-width-in-rem: get-rem-from-px($viewport-width-when-site-hits-max-width-in-px);

// Heights
$audio-player-height: 90;
// TODO: link name with blg spacing for 72px somehow?
$audio-player-height--js: 72;
$content-header-image-height--narrow-screen: 264;
$content-header-image-height--medium-width-screen: 288;
$content-header-image-height--wide-screen: 336;
$content-header-image-credit-height: 48;

$button-height-extra-small: 24;
$button-height-small: 36;
$button-height-regular: 48;

// Widths
$content-header-download-width: 20;
$content-header-download-width-medium: 44;
$content-header-profile-width: 48;

$button-speech-bubble-width-in-px: 42;

$content-header-title-font-size-xx--small: 18;
$content-header-title-font-size-x-small: 20;
$content-header-title-font-size-small: 26;
$content-header-title-font-size-medium: 30;
$content-header-title-font-size-large: 36;
$content-header-title-font-size-x-large: 41;
$content-header-title-font-size-xx-large: 46;
$content-header-title-font-size-xxx-large: 52;

// Spacing
$teaser-spacing-top: 23;
// 23 not 24 to account for a 1px border (Because of the way the elements are nested, border-box doesn't help us here.)
$teaser-spacing-bottom: 23;
$teaser-spacing-left: 16;
// TODO: link name with blg spacing for 72px somehow?
$listing-list-spacing: 72;
$site-spacing: 24;
$site-spacing-medium: 36;
$site-spacing-large: 48;
$site-spacing-extra-large: 72;

$content-header-padding: 24;
$content-header-padding-medium: 36;

// Grid column
$grid-column: 4.375rem; // 70px

// Grid gutter
$grid-gutter: 1.5rem;  // 24px

// TODO: rename these
$content-header-side-padding: 6; //vw
$content-header-side-padding--extra-wide: 30; //px

// Breakpoints

$bkpt-site--x-small: 320;
$bkpt-site--small: 480;
$bkpt-site--medium: 730;
$bkpt-site--wide: 900;
$bkpt-site--x-wide: 1200;

// For article pages
$bkpt-site--extended-wide: 1000;

// TODO: Replace the use of these with site breakpoints above
$bkpt-content-header--small-width: $bkpt-site--small;
$bkpt-content-header--medium-width: $bkpt-site--medium;
$bkpt-content-header--wide: $bkpt-site--wide;
$bkpt-content-header--extra-wide: $bkpt-site--x-wide;

//$bkpt-block-link: 600;
$bkpt-block-link: $bkpt-site--medium;
$bkpt-image-link: $bkpt-block-link;
$bkpt-grid-listing--narrow-screen: $bkpt-block-link;
$bkpt-grid-listing--medium-width: 882;
$bkpt-grid-link-listing--wide-screen: 1174;

$bkpt-captioned-asset-caption: 430;
$bkpt-view-selector: $bkpt-site--x-wide;

// TODO: review these breakpoints:
$bkpt-article-download-links-list: 560;
$bkpt-pull-quote: 560;
$bkpt-section-listing: $bkpt-site--x-wide;

// Borders
$border-radius: 3;
$border-width: 1;

// Animations
$transition-duration: 0.5s;
$transition-delay: 50ms;
$transition-timing-function: ease-in-out;
