@import "../../definitions";

.list-heading {
  @include section-title-typeg();
  @include nospace();
  letter-spacing: 0.5px;
  @include font-size-and-vertical-height(14);
  margin-bottom: 0;
  text-transform: uppercase;
}

.main-menu .list-heading {
  padding-left: 0;
  padding-right: 0;
  @include blg-spacing("top", "small");
  @include blg-spacing("bottom", "small");
  text-align: center;
}

.js .main-menu .list-heading {
  @include visuallyhidden();
}

.section-listing-wrapper .list-heading {
  @include blg-spacing("top", "small");
  padding-right: 0;
  padding-bottom: 0;
  text-align: center;
}

.highlights > .list-heading {
  @include blg-spacing("top", "extra-small");
  @include blg-spacing("bottom", "extra-small");
}

@media only screen and (min-width: #{get-rem-from-px($bkpt-section-listing)}rem) {

  .js .section-listing-wrapper .list-heading {
    @include visuallyhidden();
  }

}
