@import "../../definitions";

.jump-menu__wrapper {
  @include margin(24, "top");
  background-color: $color-text-ui-background;
}

.jump-menu__list {
  border-left: 2px solid $color-text-ui-background-light-gray;
  list-style: none;
  margin: 0;
  padding: 0;
  position: sticky;
  top: 110px;
}

.jump-menu__item {
  @include local-item-style();
}

.jump-menu {
  @include padding(18, "left");
  color: $color-text-secondary;
  display: block;
  text-decoration: none;

  &:hover {
    color: $color-text;
  }
}

.jump-menu__active {
  @include margin(-2, "left");
  border-left: 2px solid $color-primary;
  color: $color-text;
}

.wrapper--content {
  .jump-menu__wrapper {
    display: none;

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
      display: block;
      grid-column: 1/3;
      grid-row: 3;
    }
  }
}
