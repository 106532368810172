@import "../../definitions";

.nav-secondary {
  @include margin(8, "top");
  background-color: $color-text-ui-background;
  display: none;
  margin-left: auto;
  position: relative;
  z-index: 15;

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    display: inline-block;
  }
}

.nav-secondary__list {
  @include height(40);
  @include margin(0);
  @include padding(0);
}

.nav-secondary__item {
  @include nav-typeg();
  color: $color-text-ui-background--reverse;
  float: left;
  list-style-type: none;

  @supports (display: flex) {
    padding-top: 0;
  }
}

.nav-secondary__list .nav-secondary__item.nav-secondary__item--search {
  a {
    @include background-icon("../img/patterns/molecules/nav-secondary-search-black-ic.a3f2d6fa.svg");

    &:hover {
      background-image: url("../img/patterns/molecules/nav-secondary-search-blue-ic.5f946a4d.svg");
    }
  }
}

.nav-secondary__list .nav-secondary__item.nav-secondary__item--alert {
  a {
    @include background-icon("../img/patterns/molecules/nav-secondary-alert-black-ic.e9bb80fe.svg");

    &:hover {
      background-image: url("../img/patterns/molecules/nav-secondary-alert-blue-ic.6c59a5fe.svg");
    }
  }
}

.nav-secondary__item--hide-narrow {
  display: none;

  .button--default.button--extra-small {
    @include padding(8, "left");
    @include padding(8, "right");
    font-weight: normal;
  }
}

.nav-secondary__item a:not(.login-control__non_js_control_link) {

  text-decoration: none;

  &:link,
  &:visited {
    color: #212121;
  }

  &.button:link,
  &.button:hover,
  &.button:active,
  &.button:visited {
    color: #fff;
  }

}

@media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
  .nav-secondary__item--hide-narrow {
    display: list-item;
  }
}
