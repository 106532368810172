@import "../../definitions";

.media-chapter-listing-item {
  @include teaser-root-style();
}

.media-chapter-listing-item__time {
  @include teaser-context-label-style();
}

.media-chapter-listing-item__header_text {
  @include teaser-header-text-style();
}

.media-chapter-listing-item__header_text_link {
  @include teaser-header-text-link-style();
}

.media-chapter-listing-item.current-chapter {

  .media-chapter-listing-item__time,
  .media-chapter-listing-item__header_text_link {
    color: $color-primary;
  }

}

.media-chapter-listing-item__body {
  @include teaser-body-style();
  @include nospace();
  @include blg-spacing("top", "extra-small");
}

.media-chapter-listing-item__source_list {
  @include nospace();
  list-style: none;
}

.media-chapter-listing-item__source_list_item {
  @include body-typeg();
}

.media-chapter-listing-item__source_list_item_link {

  &:hover {
    @include color-link-hover();
  }
}
