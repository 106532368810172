@import "../../definitions";

.content-header-simple {
  @include blg-pad-vertical-small-to-medium();
  padding-left: 6%;
  padding-right: 6%;
  text-align: center;
}

.content-header-simple__title {
  @include h2-typeg();
  color: $color-text;
  @include font-size-and-vertical-height(20);
  @include nospace();
}

.content-header-simple__strapline {
  color: $color-text;
  @include body-typeg();
  @include nospace();
}

.clean {
  @include padding(24, "bottom");

  p {
    color: $color-text;
    font-family: $font-secondary;
  }

  .site-header {
    max-height: unset;
  }

  .site-header__title-border {
    @include margin(80, "top");
  }

  .site-header__logo_link_image {
    @include margin(60, "bottom");
  }

  .site-header__logo_link {
    width: 200px;
    height: 75px;
  }

  .content_alerts-container {
    .site-header__logo_link {
      margin-left: -5px;
    }

    .site-header__logo_link {
      width: 160px;
      height: 60px;
    }
  }

  main {
    border-top: 0;
  }

  .content-header-simple {
    @include padding(0, "left");
    text-align: left;
  }

  .form-item__title {
    @include padding(36, "bottom");

  }

  .form-item__sub_title {
    @include padding(36, "top");
  }

  .content_alerts_subscribe {
    @include margin(24, "top");
  }
}

.content_alerts-container {
  float: initial;
  margin: 0 auto;
  max-width: 540px;
}

.header-wrapper {
  @include padding(36, "top");

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    max-width: 640px;
  }

  .content-header-simple {
    @include padding(0);
  }

  .content-header-simple__title {
    @include font-size-and-vertical-height(32, 40);

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
      @include font-size-and-vertical-height(42, 52);
    }
  }

  .content-header-simple__strapline {
    @include margin(24, "top");
    font-family: $font-secondary;
    font-weight: bold;
  }
}
