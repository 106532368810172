/* These hacks are for the third party plugin Hypothesis. The background colours cannot be changed unless you override them with an !important tag. */

.hypothesis-highlight {
  background-color: rgba(2,136,209,0.2) !important;
}

.hypothesis-highlight .hypothesis-highlight {
  background-color: rgba(2,136,209,0.36) !important;
}

.hypothesis-highlight .hypothesis-highlight .hypothesis-highlight {
  background-color: rgba(2,136,209,0.5) !important;
}

.hypothesis-highlight.hypothesis-highlight-focused {
  background-color: rgba(2,136,209,0.7) !important;

  .hypothesis-highlight {
    background-color: transparent !important;
  }
}

// Cookiebot styling

@media screen and (min-width: 601px) {
  #CybotCookiebotDialog
  .CybotCookiebotDialogBodyLevelButtonSliderWrapper
  input:focus-visible + span,
  #CybotCookiebotDialog :focus-visible {
    outline: 0 !important;
  }
}
