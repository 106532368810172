@import "../../definitions";

.info-bar {
  background-color: $color-information;
  color: $color-text--reverse;
  font-family: $font-secondary;
  @include font-size(14);
  font-weight: 600;
  @include padding(14 8);
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.info-bar__text {
  background-position: 0 50%;
  background-repeat: no-repeat;
  display: inline-block;
  @include padding(2 0 0 28);
  @include font-size-and-vertical-height(14, 18.2);

  .info-bar--warning & {
    // Reset padding to that for the base info bar as no left hand side icon in this variant
    @include padding(0, "left");
  }

}

.info-bar--announcement {
  @include padding(0);
  background-color: $color-text-ui-background-secondary-light;
  color: $color-text;

  a {
    color: $color-primary;
    font-weight: 600;
    text-decoration: none;
  }

  .info-bar__container {
    @include padding(28);
    @include margin(24, "bottom");
  }

  .info-bar__text {
    @include padding(0, "top");
    background: url("../img/icons/info-blue.857b9409.svg") 0 0 no-repeat, linear-gradient(transparent, transparent);
    font-weight: 400;
    line-height: 1.7;
    text-align: left;
  }
}

.info-bar--info {

  .info-bar__text {
    background-image: url("../img/icons/info.96b39fb1.png");
    background-image: url("../img/icons/info.6662c626.svg"), linear-gradient(transparent, transparent);
  }
}

.info-bar--multiple-versions {
  background-color: $color-information;

  .info-bar__text {
    background-image: url("../img/icons/multiple-versions-article.336e926c.png");
    background-image: url("../img/icons/multiple-versions-article.96fdc4b8.svg"), linear-gradient(transparent, transparent);
    background-size: 17px;
  }
}

.info-bar--success {
  background-color: $color-success;

  .info-bar__text {
    background-image: url("../img/icons/confirmed.79ce7ac4.png");
    background-image: url("../img/icons/confirmed.c30e8709.svg"), linear-gradient(transparent, transparent);
  }
}

.info-bar--attention {
  background-color: $color-attention;

  .info-bar__text {
    background-image: url("../img/icons/attention.de73644c.png");
    background-image: url("../img/icons/attention.77e4036b.svg"), linear-gradient(transparent, transparent);
  }
}

.info-bar--correction {
  background-color: $color-attention;

  .info-bar__text {
    background-image: url("../img/icons/corrected-article.7be00f7a.png");
    background-image: url("../img/icons/corrected-article.9cd6ecfe.svg"), linear-gradient(transparent, transparent);
    background-size: 17px;
  }
}

.info-bar--dismissible {
  @include padding(24, "top");
  @include wrapper();
  background-color: inherit;
  max-width: #{$max-site-width}px;

  &:not(.hidden) {
    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  // when js is available, don't show until js has had a chance to hide it
  .js &:not([data-behaviour-initialised]) {
    display: none;
  }

  .info-bar__container {
    @include padding(14 15 9);
    background-color: $color-text-ui-background-secondary-light;
    color: $color-text;
    display: flex;
    grid-column: 2/12;

    @media only screen and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
      grid-column: 1/13;
    }
  }

  .info-bar__text {
    @include font-size-and-vertical-height(14, 24);
    @include padding(0 8 0 30);
    background: url("../img/icons/info-blue.857b9409.svg") 0 0 no-repeat, linear-gradient(transparent, transparent);
    background-size: 24px;
    font-weight: 400;
    text-align: left;
    width: 100%;

    a {
      @include padding(2, "left");
      color: $color-primary;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .dismiss-button {
    @include padding(5);
    background: url("../img/icons/close.f00467a1.svg") center no-repeat;
    border: 0;
    border-radius: 2px;
    display: inline-block;
    float: right;
    height: 24px;
    text-indent: -10000px;
    width: 24px;

    &:hover {
      background-color: rgba(0,0,0,0.15);
    }
  }
}

.info-bar--warning {
  background-color: $color-warning;
}
