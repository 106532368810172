@import "../../definitions";

.popup {
  position: relative;
}

.popup {
  position: absolute;
  width: 470px;
  z-index: 100;
}

a.popup__wrapper {
  color: inherit;
  @media only all and (min-width: #{$bkpt-site--medium}px) {
    cursor: pointer;
  }
}

a.popup__button {
  color: #fff;
  font-family: $font-secondary;
  @include font-size(11);
  @include padding(12, "left");
  @include padding(12, "right");
  @include blg-spacing("top", "extra-small");
  @include blg-spacing("bottom", "extra-small");
  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:after {
    content: "\00a\00bb";
  }

}

.popup__button--right {
  float: right;
}

.popup__content {
  margin-bottom: 0;
  @include padding(12, "left");
  @include padding(12, "right");
  @include blg-spacing("top", "extra-small");
  @include blg-spacing("bottom", "extra-small");

}

.popup__window {
  @include box-shadow();
  background: #181818;
  border-radius: #{$border-radius}px;
  color: #fff;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }

  }

}

.popup__content__ancillary {
  @include popup-ancillary-text();
  @include blg-spacing("top", "extra-small");
  @include padding($blg-space-extra-small-in-px / 2, "bottom");
}
