@import "../../definitions";

.side-by-side-view__iframe {
  border-width: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 8.6rem;
  height: calc(100% - 8.6rem);
  width: 100%;
}

.side-by-side-view__bar {
  position: absolute;
  left: 0;
  top: 6.2rem;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f7f7f7;
  width: 100%;
  height: 40px;
  @include font-size-and-vertical-height($font-size-base-in-px, 38);
}

.side-by-side-view__button-close {
  background: none;
  border: none;
  font-family: $font-secondary;
  font-weight: bold;
  @include font-size(14);
  color: $color-primary;
  padding: 0 0 0 8px;
}

.side-by-side-view__button-close-cross {
  @include font-size(18);
  vertical-align: middle;
  padding-right: 0.3rem;
  display: inline-block;
  margin-top: -2px;
}

.side-by-side-view__button-close-text {
  vertical-align: middle;
  font-weight: 500;
}
