@import "../../_definitions";

video {
  height: auto;
  max-height: 100vh;
  width: 100%;

  .captioned-asset & {
    border: 1px solid $color-text-dividers;
  }

}
