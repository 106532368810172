@import "../../definitions";

.wrapper-alternative {
  @include wrapper-alternative  ();
}

.site-header__title-border {
  @include margin(48, "top");
  float: left;
  position: relative;
  z-index: 21;
}

.personalised-cover-outer {
  @include margin(72, "bottom");
}

.personalised-cover-download {
  @include blg-pad-vertical-large-to-extra-large();
  max-width: 735px;

  > :last-child {
    margin-bottom: 0;
  }
}

.personalised-cover__header-text {
  @include margin(70 0 72 0);
  text-align: center;
}

.personalised-cover-text {
  @include padding(24, "bottom");
}

.personalised-cover-thumbnail {
  margin: 0 auto 48px;
  max-width: 348px;

  img {
    max-width: 100%;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.2));
  }
}

.personalised-cover-buttons {
  display: grid;
  justify-content: center;

  .button {
    @include colors($color-primary);
    @include padding(18 18 14);
    font-weight: 500;
    letter-spacing: 0.4px;

    &:hover {
      @include colors($color-primary-dark);
    }
  }

  .button-collection {
    display: grid;
    justify-items: center;
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    .button-collection {
      display: flex;
    }
  }

  [data-behaviour-initialised="true"] {

    .button-collection {
      grid-row: 2;
    }

    .button-collection__item {
      @include margin(12, "left");
      @include margin(12, "right");
    }
  }

  [data-behaviour-initialised="false"] {

    .button-collection {
      grid-row: auto;
      grid-column: 1;
    }
  }
}

.form-item__checkbox {
  margin: 0 auto 48px;
  grid-row: 1;
}

@media only screen and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {

  .personalised-cover-outer {
    display: grid;
    grid-template-columns: 50%;
    margin: 0 auto 72px;
    max-width: 780px;
  }

  .personalised-cover__header-text {
    @include margin(94, "top");
  }

  .personalised-cover-text {
    @include padding(20, "right");
    grid-column: 1;
    grid-row: 1;
  }

  .personalised-cover-thumbnail {
    @include margin(24, "left");
    @include margin(24, "bottom");
    grid-column: 2;
    grid-row: 1/3;
    justify-self: end;
    max-width: 324px;
  }

  .personalised-cover-buttons {
    @include margin(-72, "right");
    justify-content: left;
  }

  .form-item__checkbox {
    @include margin(0 0 72);
  }

  .button-collection__item {
    @include margin(0, "left");
    @include margin(24, "right");
  }

}
