@import "../../definitions";
@import "../../variables";

.article-assessment-terms {
  font-family: $font-secondary;
}

.article-assessment-terms__title {
  @include font-size-and-vertical-height(14,24);
  @include margin(6, "bottom");

  color: #666;
}

.article-assessment-terms__description {
  @include font-size(16);
  @include margin(12, "bottom");

  color: $color-text;
  line-height: 24px;

}

.article-assessment-terms__list {
  @include margin(0 0 36);
  @include padding(0);

  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;
}
