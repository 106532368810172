@import "../../definitions";

.contextual-data {
  @include label-content-typeg($color-text-secondary, false);
  @include font-size-and-vertical-height(12);

  .content-header & {
    border-bottom: none;
  }

  .content-header__one-column & {
    display: flex;

    &:after {
      @include margin(3, "left");
      @include margin(3, "right");
      content: "\00a0\2022\00a0";
      font-weight: bold;
      white-space: pre;
    }
  }
}

.contextual-data__list {
  border-bottom: 1px solid $color-text-dividers;
  margin: 0;
  @include padding(11 0);
  text-align: center;

  .content-header & {
    border-bottom: none;
    @include padding(0);
    text-align: right;
  }

  .content-aside & {
    @include margin(24, "bottom");
    @include padding(0);
    border: none;
    display: flex;

    .contextual-data__item {
      @include padding(0);
      @include font-size-and-vertical-height(14);

      &:before {
        content: ", ";
      }

      &:first-child {
        &:before {
          content: "";
        }
      }
    }
  }
}

.contextual-data__item {
  display: inline-block;
  @include label-content-typeg($color-text-secondary, false);
  @include font-size-and-vertical-height(12);
  margin: 0;
  @include padding(0 5 0 0);

  &[data-hypothesis-trigger] {
    cursor: pointer;
  }

  a,
  .contextual-data__counter {
    color: inherit;
  }

  a:hover {
    color: $color-primary;
  }

  .content-header & {
    @include font-size-and-vertical-height(14, 24);
    display: list-item;
    padding: 0;
    list-style: none;
  }

}

.contextual-data__item__hypothesis_opener {
  display: none;

  .js & {
    color: $color-primary;
    display: inline-block;
    cursor: pointer;
  }

}

.contextual-data__cite_wrapper {
  border-bottom: 1px solid $color-text-dividers;
  @include blg-spacing("top", "extra-small");
  @include padding($blg-space-extra-small-in-px - 1, "bottom");
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.contextual-data__cite {
  display: none;
}

.contextual-data__counter {
  color: $color-text;
  font-weight: bold;

  .content-header__footer & {
    font-weight: normal;
  }

}

@media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
  .contextual-data__list {
    .content-header & {
      text-align: left;
    }
  }
}

@media only screen and (min-width: #{get-rem-from-px($bkpt-site--wide)}rem) {
  .contextual-data {
    border-bottom: 1px solid $color-text-dividers;
  }

  .contextual-data__list {
    align-self: center;
    border-bottom: none;
    display: inline-block;
    text-align: left;

    .content-header__one-column & {
      align-self: auto;
    }
  }

  .contextual-data__cite_wrapper {
    border-bottom: none;
    float: right;
    margin-left: auto;
    @include padding(11 0);
    text-align: start;
  }

  .contextual-data__cite {
    align-self: center;
    display: inline-block;
    flex: 1;
    text-align: right;
    @include padding(0 5 0 0);
  }
}
