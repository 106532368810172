@import "../../definitions";

.trigger {
  @include font-size-and-vertical-height($font-size-base-in-px, 48);
  height: 48px;
  margin: 10px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 48px;
}

.trigger--prev {
  left: 0;
}

.trigger--next {
  right: 0;
}
