@import "../../definitions";

.article-download-links-list__link {
  font-weight: normal;

  &:hover {
    color: $color-primary-dark;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.article-download-links-list__secondary_link {
  @include font-size(14);
  font-family: $font-secondary;

  .article-download-links-list--js & {
    display: block;
    @include font-size(12);
    @include padding(24, left);
    @include margin(6, top);
    font-weight: normal;
    color: $color-primary;
  }

  &:hover {
    @include color-link-hover();
  }
}

.article-download-links-list--js {
  @include blg-spacing("bottom", "extra-small");
  @include box-shadow();
  @include width(222);
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: #{$border-radius}px;
  font-family: $font-secondary;
  left: 0;
  position: absolute;
  right: unset;
  text-align: left;
  top: 40px;
  z-index: 20;

  .article-download-list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }

    & .article-download-links-list__item:first-child {
      border-top-style: none;
    }

    &.visuallyhidden {
      padding: 0;
      border: 0;
      margin: 0;
    }
  }

  .article-download-links-list__item {
    @include margin(24, "bottom");

    dl {
      margin-bottom: 0;
    }
  }

  .article-download-links-list__link {
    display: block;
    @include padding(24, "left");
    @include padding(24, "right");
    @include menu-typeg();
    text-decoration: none;
    font-weight: normal;
  }

  .article-download-links-list__heading {
    @include font-size(12);
    @include label-content-typeg();
    margin: 0;
    @include blg-spacing("top", "small");
    @include blg-spacing("bottom", "extra-small");
    @include padding(24, "left");
    @include padding(24, "right");
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-content-header--medium-width)}em) {
    right: 45px;
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-content-header--extra-wide)}em) {
    right: 0;
  }
}

.article-download-links-list__intro {
  @include margin(24, "bottom");
}
