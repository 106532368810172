@import "../../definitions";

.error-wrapper {
  @include flexbox-centre-x-and-y();
}

.error {
  @include wrapper();
  text-align: center;
  @include blg-pad-vertical-large-to-extra-large();

  > :last-child {
    margin-bottom: 0;
  }
}

.error__icon {
  max-width: 100%;
  @include blg-spacing("bottom", "medium");
}

.error__title {
  @include h2-typeg();
  @include margin(24, "bottom");
}

.error__orcid-container {
  @include margin(96, "top");
  text-align: left;
}

.error__orcid {
  @include margin(72, "bottom");

  h3 {
    @include padding(12, "bottom");
    line-height: 30px; // Added line-height as global h3 is not correct;
  }
  .error__orcid-image {
    border: 1px solid #e0e0e0;
    padding: 12px;
    max-width: 432px;
    width: 100%;
  }

  .error__orcid-right {
    text-align: center;
  }
}

@media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {

  .error__orcid {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 730px;

    h3 {
      grid-row: 1;
      grid-column: 1/3;
      justify-self: left;
    }

    .error__orcid-left {
      grid-row: 2;
      grid-column: 1;
      margin-right: 36px;
      max-width: 329px;
      text-align: left;

    }

    .error__orcid-right {
      grid-row: 2;
      grid-column: 2;
      max-width: 365px;
    }
  }

}
