.chart-tooltip {
  padding: 4px;
}

.chart-tooltip .chart-tooltip__header {
  display: block;
  color: #fff;
  font-weight: bold;
  margin: 0;
  padding: 0 0 4px 0;
}

.chart-tooltip .chart-tooltip__content {
  display: block;
  color: #eee;
  margin: 0;
  padding: 0;
}
