@import "../../definitions";

a.additional-asset__link {
  color: $color-text;
  display: block;
  @include fig-caption-text-typeg(0);
  text-decoration: none;
  text-transform: none;

  &:hover {
    color: $color-primary;
  }

}
