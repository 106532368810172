@import "../../definitions";

.search-box {
  position: relative;

  &.search-box--shown {
    @include blg-spacing("top", "medium");
    @include blg-spacing("bottom", "small");
  }

  &:not(.search-box--js) {
    @include blg-spacing("top", "medium");
  }

}

.search-box__inner {
  max-width: #{$max-site-width}px;
  padding: 0 6%;
  position: relative;

  .wrapper & {
    padding-left: 0;
    padding-right: 0;
  }

  .wrapper .search-box--js.search-box--shown & {
    padding: 0 6%;
  }

}

.search-box__output {
  position: relative;
  width: 100%;
  z-index: 7;

  ul {
    border-left: 1px solid $color-text-dividers;
    border-right: 1px solid $color-text-dividers;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid $color-text-dividers;
    font-family: $font-secondary;
    margin: 0;
    @include padding(12, "left");
    @include padding(12, "right");
    @include blg-spacing("top", "extra-small");
    @include blg-spacing("bottom", "extra-small");

    &:first-child {
      border-top: 1px solid $color-text-dividers;
    }

    &:focus {
      background-color: $color-primary;
      color: $color-text-ui-background;

    }
  }
}

.search-box__suggestion {
  background-color: $color-text-ui-background;
  color: $color-text;
}

.search-box--js {

  @include border-radius-simple(0, 0, $border-radius, $border-radius);

  &.search-box--populated {

    .compact-form__input {
      @include padding(90, right);
    }

    .compact-form__reset {
      @include visuallyunhidden();
      background: url("../img/icons/search-clear.05d7efd9.png");
      background: url("../img/icons/search-clear.aac67c14.svg"), linear-gradient(transparent, transparent);
      background-position: -1px -1px;
      background-repeat: no-repeat;
      border-radius: 10px;
      height: 20px;
      position: absolute;
      right: 63px;
      right: #{get-rem-from-px(63)}rem;
      top: 14px;
      top: #{get-rem-from-px(14)}rem;
      width: 20px;
    }

  }

  .search-box__search_option_label {
    @include checkbox-label();
    @include blg-spacing("top", "extra-small");
  }

}

@media only all and (min-width: #{$max-site-width}px) {

  .search-box__inner {
    margin: 0 auto;
    @include padding(0 66);
  }

}
