@import "../../definitions";

.email-cta {

  border-top: 1px solid $color-text-dividers;
  @include blg-spacing("top", "medium");
  @include blg-spacing("bottom", "large");
  text-align: center;
}

.email-cta__container {
  box-sizing: content-box;
  padding: 0 6%;
  margin: 0 auto;
  max-width: 27.5rem;
}

.email-cta__header_text {
  @include h2-typeg();
  @include font-size-and-vertical-height(20);
  @include padding(24, "bottom");
  color: $color-text;
}

.email-cta__privacy {
  display: block;
  @include font-size-and-vertical-height(12, 24);
  @include margin(4, "bottom");
  @include margin(12, "top");
}

.email-cta__privacy_link {
  color: $color-text-secondary;
  font-family: $font-secondary;

  &:hover {
    color: $color-primary;
    text-decoration: underline;
  }
}
