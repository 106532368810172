@import "../../definitions";

.inline-profile {
  clear: both;
  @include blg-spacing("top", "medium", "margin");
  @include blg-spacing("bottom", "medium", "margin");
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    @include clearfix();
    margin: 0;
  }
}

.inline-profile__picture {
  border: 1px solid $color-text-dividers;
  @include blg-spacing("top", "extra-small");
  @include padding(12, "right");
  @include blg-spacing("bottom", "extra-small");
  @include padding(12, "left");
  @include blg-spacing("bottom", "small", "margin");
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    float: left;
    @include margin(24, "right");
  }
}

.inline-profile__image {
  display: block;
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    width: 120px;
  }
}
