@font-face {
  font-display: fallback;
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-Regular-webfont-custom-2-subsetting.6031b15b.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "Noto Sans";
  src: url("../fonts/NotoSans-SemiBold-webfont-custom-2-subsetting.8b9e80d5.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-display: fallback;
  font-family: "Noto Serif";
  src: url("../fonts/NotoSerif-Regular-webfont-custom-2-subsetting.e6069232.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "Noto Serif";
  src: url("../fonts/NotoSerif-Bold-webfont-basic-latin-subsetting.592fd0d5.woff2") format("woff2");
  font-weight: bold;
}
