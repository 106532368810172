@import "../../definitions";

.media-source__fallback_link {
  font-family: $font-secondary;
  @include font-size(14);
  text-decoration: none;

  &:hover {
    @include color-link-hover();
  }
}

.media-source__fallback_link--audio-player {
  background-color: $color-text;
  color: $color-text--reverse;
}
