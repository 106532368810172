@import "../../definitions";

.see-more-link {
  display: block;
  color: $color-text;
  @include see-more-link-typeg();
  text-decoration: none;

  &:after {
    content: "\00a0\00bb";
  }
}

.see-more-link--inline {
  display: inline;
  color: $color-primary;
  font: inherit;
  white-space: nowrap;
}

.see-more-link__highlight {
  color: $color-primary;
}

.see-more-link:hover {
  @include color-link-hover();
}
