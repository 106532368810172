@import "../../definitions";

.pull-quote {
  clear: both;
  // 24 is height of the image
  background: url("../img/icons/quote-start.75052289.svg") 0 #{$blg-space-large-in-px - 24 - 14}px no-repeat, linear-gradient(transparent, transparent);
  @include nospace();
  @include blg-spacing("top", "large");
  @include blg-spacing("bottom", "medium");

  p {
    @include pullquote-typeg();
    @include font-size-and-vertical-height(24, 28.8);
    margin: 0;
    padding: 0;
  }

  p + p {
    @include padding(15, "top");
  }

  p:last-of-type {
    display: inline;
    position: relative;

    &:after {
      background: url("../img/icons/quote-end.657950f9.svg") 0 0 no-repeat, linear-gradient(transparent, transparent);
      bottom: -20px;
      content: "";
      display: block;
      height: #{get-rem-from-px(33)}rem;
      @include margin(-19, "top");
      position: absolute;
      right: -#{get-rem-from-px(55)}rem;
      width: #{get-rem-from-px(44)}rem;
    }

  }
}

.pull-quote__cite {
  color: $color-text-secondary;
  display: block;
  @include quote-attrib-typeg();
  @include blg-spacing("top", "small");
}
