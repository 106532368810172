@import "../../definitions";

.code {
  @include mono-typeg;
  @include blg-spacing("bottom", "small", "margin");
  @include blg-spacing("top", "extra-small");
  @include blg-spacing("bottom", "extra-small");
  @include padding(12, "left");
  @include padding(12, "right");
  background-color: $color-text-ui-code;
  border: 1px solid $color-text-dividers;
  border-radius: #{$border-radius}px;
  color: $color-text;
  overflow-x: scroll;
}

.code code {
  white-space: pre-wrap;
}
