@import "../../definitions";

$image-height: 72;
$image-width: 185;

.investor-logos {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.investor-logos__item {
  border-top: 1px solid $color-text-dividers;
  @include blg-spacing("top", "small");
  @include blg-spacing("bottom", "small");
  @include padding(24, "left");
  @include padding(24, "right");
}

.investor-logos__container {
  @include height($image-height);
  margin: auto;
  @include constrain-width($image-width, "max");
  text-align: center;
  @supports (display: flex) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.investor-logos__picture {
  max-width: 100%;
}

.investor-logos__img {
  display: block;
  margin: auto;
  @include constrain-height($image-height, "max");
}

@media only screen and (min-width: #{get-rem-from-px(520)}em) {

  .investor-logos {
    border-top: 1px solid $color-text-dividers;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .investor-logos__item {
    border: 0;
    border-right: 1px solid $color-text-dividers;
    display: table-cell;
    vertical-align: top;

    &:last-child {
      border-right: 0;
    }
  }

}
