@import "../../definitions";

// .math-block rather than .math to avoid MathJax collision

.math-block {
  @include body-typeg();
  display: flex;
  flex-direction: row-reverse;
}

.math-block__label {
  @include padding(6, "left");
}

.math-block__math {
  display: block;
  @include body-spacing;
  flex-grow: 1;
  text-align: center;
}
