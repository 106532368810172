@import "../../definitions";

.archive-nav-link__link {
  color: $color-text;
  text-decoration: none;
}

.archive-nav-link__sub_links_list_heading {
  @include label-content-typeg();
  @include padding(8 0);
  margin-top: 0;
}

.archive-nav-link__sub_links_list_item {
  display: list-item;
  list-style-type: disc;
  @include margin(16, "left");
  @include margin(0, "bottom");
}

.archive-nav-link__sub_link {
  color: $color-text;
  @include body-typeg();
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }
}
