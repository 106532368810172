@import "../../definitions";

.filter-group {
  border: 0;
  @include nospace();
}

.filter-group__title {
  @include listing-main-subtitle-typeg();
  @include blg-spacing("bottom", "extra-small");
}

.filter-group__filters {
  list-style-type: none;
  @include nospace("left");
}

.filter-group__filter_item {
  @include font-size-and-vertical-height($font-size-base-in-px);
}

.filter-group__item_label {
  @include checkbox-label();
}

.filter-group__select {
  @include margin(24, "bottom");
  @include padding(10 18 10 12);

  appearance: none;
  background: none;
  border: 1px solid $color-text-dividers;
  cursor: pointer;
  display: block;
  font-weight: normal;
  height: 44px;
  min-width: 300px;
  background: url("../img/icons/arrow-down-filter.9d378962.svg") no-repeat bottom 10px right 10px;

  .filter-group__item_label & {
    margin-top: 6px;
  }

  @media only screen and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    min-width: 320px;
  }
}
