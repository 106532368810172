@import "../../definitions";

.select-nav--js .select {
  margin-bottom: 0;
}

// If js is present, that mediates the page request, so don't need this submit button.
.select-nav--js button[type="submit"] {
  @include visuallyhidden();
}
