@import "../../definitions";

.grid-listing {

  // Fallback for clients that don't support css grid
  @include clearfix();

  @supports (grid-gap: 0) {
    display: grid;
    @include blg-spacing("top", "small");
  }

  list-style: none;
  margin: 0 auto;
  padding-left: 0;

  // Gives the effect of medium-to-large due to 24px already on the bottom of the list items
  @include blg-margin-bottom--small-to-medium();

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.25rem;
    grid-row-gap: #{get-rem-from-px($blg-space-smallish-in-px)}rem;
    justify-content: center;

    // Supporting clients don't need clearfix
    @supports (grid-gap: 0) {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
    grid-template-columns: repeat(4, 1fr);
  }

}

.grid-listing-item {
  float: left;
  margin: 0;

  // Removed later if grid supported
  @include blg-spacing("top", "small");

  width: 100%;
  @include constrain-width($max-width-block-link, "max");

  // 2 col
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--medium)}em) {
    @include margin($grid-listing-spacing-measure, "right");

    &:nth-child(2n) {
      margin-right: 0;
    }

    width: calc((100% - #{$grid-listing-spacing-measure}px) / 2 - 0.1px); // -0.1px avoids rounding problems in IE
  }

  // 3 col
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    @include margin($grid-listing-spacing-measure, "right");

    &:nth-child(2n) {
      @include margin($grid-listing-spacing-measure, "right");
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    width: calc((100% - #{2 * $grid-listing-spacing-measure}px) / 3 - 0.1px); // -0.1px avoids rounding problems in IE
  }

  // 4 col
  @media only all and (min-width: #{get-rem-from-px($bkpt-site--x-wide)}em) {
    @include margin($grid-listing-spacing-measure, "right");

    &:nth-child(2n),
    &:nth-child(3n) {
      @include margin($grid-listing-spacing-measure, "right");
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    width: calc((100% - #{3 * $grid-listing-spacing-measure}px) / 4 - 0.1px); // -0.1px avoids rounding problems in IE
  }

  @supports (grid-gap: 0) {
    &:nth-child(n) {
      @include nospace();
      width: auto;
    }
  }

}

.grid-listing__pagination {
  @include blg-margin-bottom--medium-to-large();
  width: 100%;
}
