@import "../../definitions";

.call-to-action-wrapper {
  box-sizing: border-box;
  border-bottom: 1px solid $color-text-dividers;
  width: 100%;

  display: block;

  // If js is required, always turn off element to start with, it may be enabled if js is present
  &.call-to-action-wrapper--needs-js {
    display: none;
  }

  // If js is available, always turn off element to start with, it will be enabled if should be shown with js
  .js & {
    display: none;
  }

  // If js is available, and the element should be shown with js, turn it on
  .js &.call-to-action-wrapper--js-shown:not(.hidden) {
    display: block;
  }

  @supports (display: flex) {
    display: flex;

    .js &.call-to-action-wrapper--js-shown:not(.hidden) {
      display: flex;
    }

  }

  justify-content: center;
  align-items: center;

  &.hidden {
    @include hidden();
  }
}

.call-to-action {
  @include padding(24, "top");
  @include padding(24, "bottom");
  margin: 0 auto;

  @supports (display: grid) {
    display: grid;
  }

  grid-template-columns: 80px 1fr 12px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: #{get-rem-from-px($blg-space-small-in-px)}rem;

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    display: table;
    @supports (display: grid) {
      display: grid;
    }
    grid-template-columns: 80px 1fr auto 28px;
  }

  .dismiss-button {
    @supports (display: grid) {
      background: url("../img/icons/close.f00467a1.svg") center right / 12px 12px no-repeat, linear-gradient(transparent, transparent) transparent;
      cursor: pointer;
      border-width: 0;
      padding: 0;
      margin: 0;
      display: block;
      grid-column: 3 / -1;
      grid-row: 1;
      justify-self: end;
      align-self: start;
      width: 12px;
      height: 12px;

      @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
        grid-column: 4;
        justify-self: unset;
        box-sizing: content-box;
        padding-left: 16px;
      }
    }
  }

}

.call-to-action__image {
  box-sizing: content-box;
  // Uses non-standard 4px where $border-radius is 3px because buttons also use 4px and this is near a button
  border-radius: 4px;
  float: left;
  height: 80px;
  min-height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 16px;
  @include blg-spacing("bottom", "small");

  @supports (display: grid) {
    margin-right: 0;
    padding-bottom: 0;
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--small)}em) {
    margin-right: 16px;

    @supports (display: grid) {
      margin-right: 0;
    }

  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    display: table-cell;

    @supports (display: grid) {
      display: block;
    }

  }

}

.call-to-action__text {
  grid-column: 2 / -2;
  grid-row: 1;
  align-self: center;
  @include blg-spacing("bottom", "small");
  font-family: $font-secondary;

  @supports (display: grid) {
    padding-bottom: 0;
  }

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    grid-column: 2;
    display: table-cell;
    vertical-align: middle;

    @supports (display: grid) {
      display: block;
    }
  }

}

.call-to-action__button_wrapper {
  grid-column: 1 / -1;
  justify-self: center;
  align-self: center;

  @media only all and (min-width: #{get-rem-from-px($bkpt-site--wide)}em) {
    @include blg-spacing("bottom", "small");
    @include padding(16, "left");
    display: table-cell;
    vertical-align: middle;

    @supports (display: grid) {
      display: block;
      padding-bottom: 0;
      padding-left: 0;
    }

    grid-column: 3;
    grid-row: 1;
  }

}
