@import "../../definitions";

.about-profile__name {
  @include font-size-and-vertical-height(18);
  @include nospace();
  @include blg-spacing("top", "extra-small");

  .popup & {
    padding-top: 0;
    @include popup_title_text();
  }
}

.about-profile__role {
  margin: 0;
  @include blg-spacing("bottom", "small", "margin");

  .popup & {
    @include popup-large-ancillary-text();
    margin-bottom: 0;
  }
}

.about-profile__profile {
  @include blg-spacing("top", "small", "margin");

  @media only all and (min-width: #{$bkpt-site--medium}px) {

    .about-profile--popup & {
      display: none;

      .popup & {
        display: block;
        margin-top: 0;

        & p,
        dt,
        dd {
          @include popup-large-ancillary-text();
        }

        & *:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
}

.about-profile__picture {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;

  img {
    display: block;
  }

  @media only all and (min-width: #{$bkpt-site--medium}px) {
    float: right;
    width: 250px;
    @include blg-spacing("top", "extra-small", "margin");
    @include blg-spacing("bottom", "extra-small", "margin");
    @include margin(24, "left");
    margin-right: 0;

    .about-profiles__item .about-profile--popup & {
      float: none;
      width: 100%;
      margin: 0;
    }

    .popup & {
      display: none;
    }
  }
}

.about-profile__image {
  width: 100%;
}
