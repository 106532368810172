@import "../../definitions";

.checkboxes__list {
  list-style-type: none;
  @include nospace("left");

  label {
    cursor: pointer;
  }
}

.checkboxes__children_list {
  list-style-type: none;
  padding: 0;
}

.checkboxes__list_item {
  @include font-size-and-vertical-height($font-size-base-in-px);
}

.checkboxes__item_label {
  @include checkbox-label();
  @include padding(24, "bottom"); // Added due to mixin checkbox-label being used elsewhere
  display: flex;
  font-weight: normal;

  input[type="checkbox"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    content: none;
    height: 24px;
    margin-right: 8px;
    outline: none;
    position: relative;
    width: 24px;
  }

  input[type="checkbox"]:before {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid $color-text-dividers;
    border-radius: 2px;
    content: " ";
    color: transparent !important;
    cursor: pointer;
    display: block;
    height: 24px;
    left: -1px;
    outline: none;
    padding-bottom: 10px;
    position: relative;
    text-align: center;
    width: 24px;
    top: -1px;

  }

  input[type="checkbox"]:checked:before {
    background: $color-primary url("../img/icons/checked.b1c4db70.svg") 0 0 no-repeat;
    border: none;
  }

  .checkboxes__item {
    display: inline-block;
  }
}

.checkboxes--invalid {
  .checkboxes__item_label {
    color: $color-attention;
  }

  input[type="checkbox"]:before {
    border-color: $color-attention;
  }
}
